import { create } from 'zustand';

type Referral = {
  id: string | null;
  user_id: string | null;
  referral_code: string | null;
  referred_users: string[] | null;
  created_date: string | null;
  updated_date: string | null;
  links: string[] | null;
  transactions: any[] | null;
  summary: Summary | null;
};

type Summary = {
  total_earned: number;
  currency: string;
  avg_earned: number;
  avg_commission: number;
};

type ReferralStore = {
  referralState: Referral;
  setReferral: (referral: Referral) => void;
};

const initialState: ReferralStore = {
  referralState: {
    id: null,
    user_id: null,
    referral_code: null,
    referred_users: [],
    created_date: null,
    updated_date: null,
    links: [],
    transactions: [],
    summary: null,
  },
  setReferral: () => null,
};

export const useReferralStore = create((set) => ({
  ...initialState,
  setReferral: (referral: Referral) => {
    return set(({ referralState }) => ({
      referralState: { ...referralState, ...referral },
    }));
  },
}));

