import SoftAvatar from 'components/SoftAvatar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';

function ProductCell({ image, name, orders }) {
  return (
    <SoftBox display='flex' alignItems='center' pr={2}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} variant='rounded' />
      </SoftBox>
      <SoftBox display='flex' flexDirection='column'>
        <SoftTypography variant='button' fontWeight='medium'>
          {name}
        </SoftTypography>
        <SoftTypography variant='button' fontWeight='medium' color='secondary'>
          <SoftTypography component='span' variant='button' color='success'>
            {orders}
          </SoftTypography>{' '}
          orders
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orders: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ProductCell;

