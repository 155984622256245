import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useReferralStore } from 'stores/version';
import { fDate } from 'utils/formatTime';
import { toastSuccess } from 'utils/toast';

import { useGenerateReferralLinks } from '../../hooks';

const ReferralLinks = ({ links }) => {
  const handleCopied = () => {
    toastSuccess({ description: 'Copied to clipboard' });
  };
  return (
    <>
      {links.map((link, index) => (
        <SoftBox display='flex' alignItems='center' mb={2} key={index}>
          <SoftBox width='90%' mr={1}>
            <SoftInput
              size='small'
              value={link}
              icon={{ component: 'lock', direction: 'right' }}
              disabled
            />
          </SoftBox>

          <CopyToClipboard text={link} onCopy={handleCopied}>
            <Tooltip title='Copy referral link' placement='top'>
              <SoftButton
                variant='outlined'
                color='secondary'
                size='small'
                sx={{ padding: '0.5rem 1rem' }}
              >
                copy
              </SoftButton>
            </Tooltip>
          </CopyToClipboard>
        </SoftBox>
      ))}
    </>
  );
};
ReferralLinks.defaultProps = {
  links: [],
};

// Typechecking props of the ReferralLink
ReferralLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.string),
};
function ReferralCode() {
  const { secondary } = colors;
  const { borderWidth } = borders;
  const { referralState } = useReferralStore();
  const { mutate: generateReferralLinks, isLoading } =
    useGenerateReferralLinks();

  const generateReferralLinksHandler = () => {
    generateReferralLinks();
  };

  return (
    <>
      <SoftBox lineHeight={1}>
        <SoftTypography variant='h6' fontWeight='medium'>
          Referral Link
        </SoftTypography>
        <SoftTypography variant='button' fontWeight='regular' color='text'>
          Copy the link bellow to your registered provider.
        </SoftTypography>
      </SoftBox>
      <SoftBox
        borderRadius='md'
        border={`${borderWidth[1]} dashed ${secondary.main}`}
        pt={2}
        pb={1.5}
        px={2}
        mt={2}
      >
        <SoftBox mb={1} lineHeight={0}>
          <SoftButton
            color='success'
            size='small'
            sx={{ padding: '0.5rem 1rem' }}
            onClick={generateReferralLinksHandler}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Generate new links
          </SoftButton>
        </SoftBox>

        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant='caption' color='text'>
            Generated on {fDate(_.get(referralState, 'updated_date', ''))}
          </SoftTypography>
        </SoftBox>
        <ReferralLinks links={_.get(referralState, 'links', [])} />

        <SoftBox mb={0.5} lineHeight={1.2}>
          <SoftTypography component='p' variant='caption' color='text'>
            You cannot generate codes.
          </SoftTypography>
          <SoftTypography variant='caption' color='text'>
            <SoftTypography
              component={Link}
              variant='caption'
              href='https://www.bitmambo.com/fr/faq'
              className='color-background'
            >
              Contact us
            </SoftTypography>{' '}
            to generate more referrals link.
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}

export default ReferralCode;

