import _ from 'lodash';
import * as Yup from 'yup';

export const defaultValues = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
};

export const schema = Yup.object().shape({
  first_name: Yup.string()
    .required('First name is required')
    .max(100, 'First name is too long, should be less than 100 characters'),
  last_name: Yup.string()
    .required('Last name is required')
    .max(100, 'Last name is too long, should be under 100 characters'),
  email: Yup.string().email('Email is invalid').required('Email is required'),
  password: Yup.string().required('password is required'),
});
