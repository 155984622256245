import Referral from 'pages/referral';
import Shop from 'rootComponents/Icons/Shop';

const EmptyComponent = () => {
  return <div></div>;
};

const routes = [
  {
    type: 'collapse',
    name: 'Referral',
    key: 'referral',
    icon: <Shop size='12px' />,
    route: '/referral',
    noCollapse: true,
    component: <Referral />,
  },
];

export default routes;

