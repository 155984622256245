import chat from 'assets/images/illustrations/chat.png';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import IllustrationLayout from 'rootComponents/LayoutContainers/IllustrationLayout';

import { useSignIn } from './useAuth';

function SignIn() {
  const { register, handleSubmit } = useForm({
    defaultValues: { email: '', password: '' },
  });

  const { mutate, isLoading } = useSignIn();

  const handleSubmitForm = (values) => {
    mutate(values);
  };

  return (
    <IllustrationLayout
      title='Sign In'
      description='Enter your email and password to sign in'
      illustration={{
        image: chat,
        title: '"Attention is the new currency"',
        description:
          'The more effortless the writing looks, the more effort the writer actually put into the process.',
      }}
    >
      <SoftBox
        component='form'
        role='form'
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <SoftBox mb={2}>
          <SoftInput
            type='email'
            placeholder='Email'
            size='large'
            {...register('email')}
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput
            type='password'
            placeholder='Password'
            size='large'
            {...register('password')}
          />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton
            variant='gradient'
            color='info'
            size='large'
            fullWidth
            type='submit'
            disabled={isLoading}
          >
            sign in
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign='center'>
          <SoftTypography variant='button' color='text' fontWeight='regular'>
            Don&apos;t have an account?{' '}
            <SoftTypography
              component={Link}
              to='/sign-up'
              variant='button'
              color='info'
              fontWeight='medium'
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignIn;

