import { useState, useEffect } from 'react';

import { MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import logoSpotify from 'assets/images/small-logos/logo-spotify.svg';
import team2 from 'assets/images/team-2.jpg';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context';
import { useLogout } from 'pages/sign-in/useAuth';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from 'rootComponents/Breadcrumbs';
import NotificationItem from 'rootComponents/Items/NotificationItem';
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from 'rootComponents/Navbars/MainNavbar/styles';

function MainNavbar({ absolute, light, isMini, customRoutes }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openUserControl, setOpenUserControl] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenUserControl = (event) =>
    setOpenUserControl(event.currentTarget);
  const handleCloseUserControl = () => setOpenUserControl(false);

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt='person' />}
        title={['New message', 'from Laur']}
        date='13 minutes ago'
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt='person' />}
        title={['New album', 'by Travis Scott']}
        date='1 day'
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color='secondary'
        image={
          <Icon
            fontSize='small'
            sx={{ color: ({ palette: { white } }) => white.main }}
          >
            payment
          </Icon>
        }
        title={['', 'Payment successfully completed']}
        date='2 days'
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  const renderUserControl = () => (
    <Menu
      anchorEl={openUserControl}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openUserControl)}
      onClose={handleCloseUserControl}
      sx={{ mt: 2 }}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color='inherit'
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox
          color='inherit'
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          {customRoutes.length === 0 && (
            <Breadcrumbs
              icon='home'
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          )}
          {customRoutes.length !== 0 && (
            <Breadcrumbs
              icon='home'
              title={customRoutes[customRoutes.length - 1].label}
              route={customRoutes}
              light={light}
              customRoute={customRoutes}
            />
          )}

          <Icon
            fontSize='medium'
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? 'menu_open' : 'menu'}
          </Icon>
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox pr={1}>
              <SoftInput
                placeholder='Type here...'
                icon={{ component: 'search', direction: 'left' }}
              />
            </SoftBox>
            <SoftBox color={light ? 'white' : 'inherit'}>
              <IconButton
                sx={navbarIconButton}
                size='small'
                onClick={handleOpenUserControl}
              >
                <Icon
                  sx={({ palette: { dark, white } }) => ({
                    color: light ? white.main : dark.main,
                  })}
                >
                  account_circle
                </Icon>
              </IconButton>
              {renderUserControl()}
              <IconButton
                size='small'
                color='inherit'
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              <IconButton
                size='small'
                color='inherit'
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
              </IconButton>
              {renderMenu()} */}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of MainNavbar
MainNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  customRoutes: [],
};

// Typechecking props for the MainNavbar
MainNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  customRoutes: PropTypes.arrayOf(PropTypes.object),
};

export default MainNavbar;

