import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useReferralStore } from 'stores/version';
import request from 'utils/request';

const GET_REFERRAL_PATH = 'referral/';
const GEN_REFERRAL_LINKS = 'referral/link/';

const getReferral = () => {
  return request.get(GET_REFERRAL_PATH);
};

const generateReferralLinks = () => {
  return request.post(GEN_REFERRAL_LINKS);
};

export const useGetReferral = () => {
  const { setReferral } = useReferralStore();
  return useQuery([GET_REFERRAL_PATH], getReferral, {
    onSuccess: (data) => {
      setReferral(data);
    },
  });
};

export const useGenerateReferralLinks = () => {
  const queryClient = useQueryClient();
  return useMutation(generateReferralLinks, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_REFERRAL_PATH] });
    },
  });
};
