import _ from 'lodash';
import { useMutation } from 'react-query';
import { removeToken } from 'utils/cache';
import { setToken } from 'utils/cache';
import request from 'utils/request';
const SIGN_UP_PATH = 'user/lite/create-account';

const signUp = ({ email, password, first_name, last_name }) => {
  return request.post(SIGN_UP_PATH, {
    email,
    password,
    first_name,
    last_name,
    version: 'global',
    type: 'referral',
  });
};

export const useSignUp = () => {
  return useMutation(signUp, {
    onSuccess: () => {
      window.location.href = '/sign-in';
    },
  });
};
