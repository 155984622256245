import { useState, useEffect, useMemo } from 'react';

// eslint-disable-next-line import/order
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
// eslint-disable-next-line import/order
import { ThemeProvider } from '@mui/material/styles';

import 'react-toastify/dist/ReactToastify.css';

import brand from 'assets/images/small-logos/bitmambo-logo.png';
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';
import { useSoftUIController, setMiniSidenav } from 'context';
import SignIn from 'pages/sign-in';
import SignUp from 'pages/sign-up';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import Configurator from 'rootComponents/Configurator';
import Sidenav from 'rootComponents/Sidenav';
import routes from 'routes';
import rtlPlugin from 'stylis-plugin-rtl';

const queryClient = new QueryClient();

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const customRoutes = () => {
    return (
      <>
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/sign-up' element={<SignUp />} />

        <Route path='*' element={<Navigate to='/referral' />} />
      </>
    );
  };

  return direction === 'rtl' ? (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          <ToastContainer />
          {layout === 'dashboard' && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName='Referral Panel'
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === 'vr' && <Configurator />}
          <Routes>{getRoutes(routes)}</Routes>
          {customRoutes()}
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  ) : (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName='Referral Panel'
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === 'vr' && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path='/sign-in' element={<SignIn />} />
          {customRoutes()}
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

