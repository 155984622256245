import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '@mui/material/Checkbox';
import rocket from 'assets/images/illustrations/rocket-white.png';
import { TextInputField } from 'components/FormField';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import IllustrationLayout from 'rootComponents/LayoutContainers/IllustrationLayout';

import { useSignUp } from './hook';
import { defaultValues, schema } from './schemas';
// Images

function SignUp() {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = methods;

  const { mutate, isLoading } = useSignUp();

  const [agreement, setAgreemnet] = useState(true);

  const handleSetAgremment = () => setAgreemnet(!agreement);

  const onSubmit = (values) => {
    mutate(values);
  };

  return (
    <IllustrationLayout
      title='Sign Up'
      description='Enter your email and password to register'
      illustration={{
        image: rocket,
        title: 'Your journey starts here',
        description:
          'Just as it takes a company to sustain a product, it takes a community to sustain a protocol.',
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SoftBox>
          <SoftBox mb={2}>
            <TextInputField
              placeholder='First Name'
              size='large'
              name='first_name'
            />
          </SoftBox>
          <SoftBox mb={2}>
            <TextInputField
              placeholder='Last Name'
              size='large'
              name='last_name'
            />
          </SoftBox>
          <SoftBox mb={2}>
            <TextInputField
              name='email'
              type='email'
              placeholder='Email'
              size='large'
            />
          </SoftBox>
          <SoftBox mb={2}>
            <TextInputField
              name='password'
              type='password'
              placeholder='Password'
              size='large'
            />
          </SoftBox>
          <SoftBox display='flex' alignItems='center'>
            <Checkbox checked={agreement} onChange={handleSetAgremment} />
            <SoftTypography
              variant='button'
              fontWeight='regular'
              onClick={handleSetAgremment}
              sx={{ cursor: 'pointer', userSelect: 'none' }}
            >
              &nbsp;&nbsp;I agree the&nbsp;
            </SoftTypography>
            <SoftTypography
              component='a'
              href='#'
              variant='button'
              fontWeight='bold'
              textGradient
            >
              Terms and Conditions
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant='gradient'
              color='info'
              size='large'
              fullWidth
              type='submit'
              disabled={isLoading}
              isLoading={isLoading}
            >
              sign up
            </SoftButton>
          </SoftBox>
          <SoftBox mt={3} textAlign='center'>
            <SoftTypography variant='button' color='text' fontWeight='regular'>
              Already have an account?&nbsp;
              <SoftTypography
                component={Link}
                to='/sign-in'
                variant='button'
                color='info'
                fontWeight='bold'
                textGradient
              >
                Sign in
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </FormProvider>
    </IllustrationLayout>
  );
}

export default SignUp;

