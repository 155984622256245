import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';

function DefaultCell({ children }) {
  return (
    <SoftTypography variant='button' fontWeight='medium' color='text'>
      {children}
    </SoftTypography>
  );
}

DefaultCell.propTypes = {
  children: PropTypes.string.isRequired,
};

export default DefaultCell;

